// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Button from "../../../styleguide/components/Button/Button.res.js";
import * as GoogleMap from "../../../bindings/GoogleMap.res.js";
import * as IconClose from "../../../styleguide/icons/IconClose.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pagination from "../../../styleguide/components/Pagination/Pagination.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as InfoBoxLocationTile from "./InfoBoxLocationTile.res.js";
import * as InfoBoxLocationScss from "./InfoBoxLocation.scss";

var css = InfoBoxLocationScss;

function InfoBoxMultipleLocations(props) {
  var setIndex = props.setIndex;
  var index = props.index;
  var locations = props.locations;
  var $$location = Belt_Array.getExn(locations, index);
  var lat = Belt_Array.reduce(Belt_Array.map(locations, (function ($$location) {
              return $$location.latitude;
            })), 0.0, (function (a, b) {
          return a + b;
        })) / locations.length;
  var lng = Belt_Array.reduce(Belt_Array.map(locations, (function ($$location) {
              return $$location.longitude;
            })), 0.0, (function (a, b) {
          return a + b;
        })) / locations.length;
  var position = {
    lat: lat,
    lng: lng
  };
  return JsxRuntime.jsx(GoogleMap.InfoBox.make, {
              position: position,
              contentWidth: 270.0,
              contentHeight: 392.0,
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx(Button.make, {
                            size: "MD",
                            color: "Primary",
                            className: css.closeBtn,
                            onClick: props.closeInfoBox,
                            children: JsxRuntime.jsx(IconClose.make, {
                                  title: "Close",
                                  size: "MD",
                                  color: "White"
                                })
                          }),
                      JsxRuntime.jsx(InfoBoxLocationTile.make, {
                            location: $$location,
                            userLoginStatus: props.userLoginStatus
                          }),
                      JsxRuntime.jsx(Pagination.make, {
                            currentPage: index + 1 | 0,
                            totalPages: locations.length,
                            onPageClick: (function (newIndex) {
                                setIndex(function (param) {
                                      return newIndex - 1 | 0;
                                    });
                              }),
                            className: css.paginator,
                            specialPages: Belt_Array.keep(Belt_Array.mapWithIndex(locations, (function (index, $$location) {
                                        if ($$location.sponsoredCity) {
                                          return index + 1 | 0;
                                        } else {
                                          return -1;
                                        }
                                      })), (function (index) {
                                    return index > 0;
                                  })),
                            specialClassName: css.sponsoredMark
                          }),
                      JsxRuntime.jsx("div", {
                            className: css.arrow
                          })
                    ],
                    className: Cx.cx([
                          css.infoWindow,
                          css.withPagination
                        ])
                  })
            });
}

var make = InfoBoxMultipleLocations;

export {
  css ,
  make ,
}
/* css Not a pure module */
