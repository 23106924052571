// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../styleguide/components/Link/A.res.js";
import * as Money from "../../../libs/Money.res.js";
import * as Picture from "../../../styleguide/forms/Picture/Picture.res.js";
import * as $$Location from "../../../models/Location.res.js";
import * as IconProductsB from "../../../styleguide/icons/IconProductsB.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as InfoBoxLocationScss from "./InfoBoxLocation.scss";

var css = InfoBoxLocationScss;

function InfoBoxLocationTile(props) {
  var $$location = props.location;
  var match = $$location.productsCount;
  var tmp;
  if (match !== 0) {
    var x = $$location.productsCount;
    var tmp$1;
    tmp$1 = props.userLoginStatus === "LoggedIn" ? " from " + (Money.format($$location.minPrice) + (" to " + Money.format($$location.maxPrice))) : "";
    tmp = JsxRuntime.jsxs("div", {
          children: [
            JsxRuntime.jsx(IconProductsB.make, {
                  size: "SM",
                  color: "DarkGray"
                }),
            (
              x !== 1 ? String(x) + " products" : "1 product"
            ) + tmp$1
          ],
          className: css.productsInfo
        });
  } else {
    tmp = null;
  }
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsxs(A.make, {
                      href: $$location.url,
                      targetBlank: true,
                      className: css.location,
                      children: [
                        JsxRuntime.jsx(Picture.make, {
                              src: $$location.imageUrl,
                              large: [
                                270,
                                180
                              ],
                              className: css.image,
                              fallbackSrc: $$Location.imageNotFoundUrl
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("section", {
                                      children: $$location.providerName,
                                      className: css.provider
                                    }),
                                JsxRuntime.jsx("section", {
                                      children: $$location.name,
                                      className: css.name
                                    }),
                                JsxRuntime.jsx("section", {
                                      children: $$location.fullAddress,
                                      className: css.address
                                    }),
                                tmp
                              ],
                              className: css.details
                            })
                      ]
                    }),
                $$location.sponsoredCity ? JsxRuntime.jsx("div", {
                        children: "Sponsored Location",
                        className: css.sponsored
                      }) : null,
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(Picture.make, {
                            src: $$location.providerLogo,
                            large: [
                              70,
                              54
                            ],
                            crop: "Fit"
                          }),
                      className: css.logo
                    })
              ]
            });
}

var make = InfoBoxLocationTile;

export {
  css ,
  make ,
}
/* css Not a pure module */
