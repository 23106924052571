// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";
import * as LocationsBannerMap from "../../common/locations-banner-map/LocationsBannerMap.res.js";

function LocationsIndexMap(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(LocationsBannerMap.make, {
                    locations: props.locations
                  }),
              className: props.className
            });
}

var make = LocationsIndexMap;

var $$default = LocationsIndexMap;

export {
  make ,
  $$default as default,
}
/* react/jsx-runtime Not a pure module */
