// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as Colors from "../styles/Colors.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconProductsB(props) {
  var color = props.color;
  var __title = props.title;
  var title = __title !== undefined ? __title : "Products";
  return JsxRuntime.jsxs(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: [
                JsxRuntime.jsx("rect", {
                      height: "16",
                      width: "16",
                      fill: Colors.white,
                      x: "0",
                      y: "0"
                    }),
                JsxRuntime.jsx("rect", {
                      height: "5.4",
                      width: "5.4",
                      fill: "none",
                      rx: "0.75",
                      stroke: Icon.mapColor(color),
                      strokeWidth: "1",
                      x: "1.56666667",
                      y: "9.03333333"
                    }),
                JsxRuntime.jsx("rect", {
                      height: "5.4",
                      width: "5.4",
                      fill: "none",
                      rx: "0.75",
                      stroke: Icon.mapColor(color),
                      strokeWidth: "1",
                      x: "1.56666667",
                      y: "1.56666667"
                    }),
                JsxRuntime.jsx("rect", {
                      height: "5.4",
                      width: "5.4",
                      fill: "none",
                      rx: "0.75",
                      stroke: Icon.mapColor(color),
                      strokeWidth: "1",
                      x: "9.03333333",
                      y: "1.56666667"
                    }),
                JsxRuntime.jsx("rect", {
                      height: "6.4",
                      width: "6.4",
                      fill: Icon.mapColor(color),
                      fillRule: "nonzero",
                      rx: "0.75",
                      x: "8.53333333",
                      y: "8.53333333"
                    })
              ]
            });
}

var make = IconProductsB;

export {
  make ,
}
/* Icon Not a pure module */
